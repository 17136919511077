import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import {
  Avatar,
  IconButton,
  Menu,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Chip,
  Divider,
  MenuItem
} from '@material-ui/core';
import { AdapterLink } from './RouterLink';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { observer } from 'mobx-react-lite';
import { useStores } from '../stores';
const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    padding: 7
  },
  name: {
    fontWeight: 600
  },
  inline: {
    color: 'rgba(0, 0, 0, 0.55)'
  },
  avatar: {
    width: 35,
    height: 35
  }
}));

export const UserMenu = () => {
  //   const { user, signOut, hasAnyRoles } = useUserState();
  const classes = useStyles({});
  const { authStore } = useStores();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user = authStore.user.val();
  const isMenuOpen = Boolean(anchorEl);

  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  // const AccountImage = () => <Avatar alt={'display'} src={''} className={classes.avatar} />;

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="account-avatar-menu"
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        className={classes.icon}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="account-avatar-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <AccountCircle />
          </ListItemAvatar>
          <ListItemText
            primary={user?.name || ''}
            className={classes.name}
            secondary={
              <Typography component="div">
                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                  {user?.email || ''}
                </Typography>
                <br />
              </Typography>
            }
          />
        </ListItem>
        <Divider key="divider-1" /><MenuItem onClick={() => authStore.logout()}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default observer(UserMenu);
