import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, split, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { CurrentAuthContext, CurrentAuthContextType } from './contexts/CurrentAuth';
import * as React from 'react';

const authLink = setContext(async (_, { headers }) => {
  const token = window.localStorage.getItem('mntg.jwtToken');
  const clientGroupId = '';
  return {
    headers: {
      ...headers,
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
  };
});

const createApolloClient = () => {
  const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

  if (!GRAPHQL_ENDPOINT) {
    throw new Error('Cannot find `GRAPHQL_ENDPOINT` variable!');
  }

  const httpLink = new HttpLink({
    uri: GRAPHQL_ENDPOINT,
    headers: {},
  });

  const link = split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition';
  }, httpLink);

  return new ApolloClient({
    link: ApolloLink.from([authLink, link]),
    cache: new InMemoryCache(),
  });
};

function ApolloWrapper(props: { children: React.ReactNode }) {
  const client = createApolloClient();

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default ApolloWrapper;

// const createApolloClient = (jwtToken: CurrentAuthContextType['jwtToken']) => {
//   const httpLink = new HttpLink({
//     uri: process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT,
//     credentials: 'include',
//     headers: jwtToken
//       ? {
//           Authorization: `Bearer ${jwtToken}`
//         }
//       : undefined
//   });

//   const link = split(({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition';
//   }, httpLink);

//   return new ApolloClient({
//     link: link,
//     cache: new InMemoryCache()
//   });
// };

// function ApolloWrapper(props: { children: React.ReactNode }) {
//   const currentAuth = React.useContext(CurrentAuthContext);
//   const client = createApolloClient(currentAuth.jwtToken);

//   return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
// }

// export default ApolloWrapper;
