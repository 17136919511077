import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../stores';
import { CircularProgress } from '@material-ui/core';

export const TokenVerifier: React.FC = ({ children }) => {
  const { verifying } = useAuth();
  console.log('TokenVerifier', verifying);
  if (verifying) {
    return <CircularProgress size="64" />;
  }

  return <Fragment>{children}</Fragment>;
};

export default observer(TokenVerifier);
