import { TextField, TextFieldProps, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { ValidationOptions } from 'react-hook-form';
import { useQuery } from 'react-query';

import { client } from '../api';
import { deeppick } from '../utils/data';
import { useFormContext } from '@aginix/mui-react-hook-form-input';
import Loading from '../components/Loading';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: '100%',
    },
  })
);

const fetchFn = async (
  key: string,
  { resource, queryString = '', field }: { resource: string; field: string; queryString?: string }
) => {
  const { data: payload } = await client.get(`/${resource}?${queryString}`);
  return payload.data;
};

export interface Props<
  T extends object = {},
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> {
  name: string;
  field: string;
  queryString?: string;
  resource: string;
  validationOptions?: ValidationOptions;
  TextFieldProps?: TextFieldProps;
  filterOptionsBy?: string[];
}

/**
 *
 * @example <Autocomplete name="" resource="" validateOptions={{ required: true }} />
 * @example <Autocomplete name="" resource="" />
 * @param resource คือ ชื่อ URI ที่ใช้ค้นหา
 * @param name คือ ชื่อ field สำหรับใช้กับ react-hook-form
 */
export const Autocomplete = ({
  name,
  field,
  resource,
  validationOptions,
  filterOptionsBy = ['name'],
  TextFieldProps,
  queryString,
  defaultValue,
  ...rest
}: Props) => {
  const { register, setValue, errors } = useFormContext();

  useEffect(() => {
    register!(name, validationOptions);
  }, [register, name, validationOptions]);

  const classes = useStyles({});
  const { data, isFetching } = useQuery([resource, { resource, queryString, field: field }], fetchFn, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
  const onChange = (event: React.ChangeEvent<{}>, value: any) => {
    setValue!(name, value?.id);
  };
  if (!data) {
    return (
      <Box>
        <Loading size={24} />
      </Box>
    );
  }

  return (
    <MuiAutocomplete
      className={classes.formControl}
      options={data}
      loading={isFetching}
      onChange={onChange}
      autoHighlight
      defaultValue={data && data.find((item: any) => defaultValue === item.id)}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.formControl}
          error={!!errors![name]}
          size="small"
          margin="dense"
          variant="outlined"
          {...TextFieldProps}
        />
      )}
      filterOptions={(options, state) => {
        return options.filter((option) => {
          const values = deeppick(option, filterOptionsBy).join(' ');
          return values.includes(state.inputValue);
        });
      }}
      getOptionLabel={(data: any) => data.name}
      {...rest}
    />
  );
};

export default Autocomplete;
