import { observable, action, runInAction } from 'mobx';
import Loadable from './Loadable';
import { client } from '../api';

export interface Role {
  id: string;
  name: string;
  value: string;
}

export class RoleStore {
  @observable data = Loadable.create<Role[]>([]);

  @action
  async fetch() {
    if (this.data.isLoading()) return;
    if (this.data.val()?.length) return;

    this.data.setLoading(true);

    const { data: payload } = await client.get(`/roles`);

    runInAction('fetchRoleList', () => {
      this.data.setLoading(false);
      this.data.set(payload.data);
    });
  }
}
