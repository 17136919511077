import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useAdmin } from '../../stores';
import ContentCard from '../../components/ContentCard';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '../../Form';
import Form, { TextField } from '@aginix/mui-react-hook-form-input';
import { Grid } from '@material-ui/core';
import LoadingButton from '../../components/LoadingButton';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  image: {
    width: '100%',
  },
  contentCard: {
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

const AdminEditBuildingFloorPage = observer(() => {
  const { createBuildingStore } = useAdmin();
  const params = useParams();
  const id = params['id'];

  useEffect(() => {
    createBuildingStore.fetch(id);
  }, [createBuildingStore, id]);

  return (
    <ContentCard title="แก้ไขข้อมูลตึก/ชั้น" loading={createBuildingStore.fetching}>
      <EditForm />
    </ContentCard>
  );
});

const EditForm = () => {
  const classes = useStyles({});
  const { createBuildingStore } = useAdmin();
  const form = useForm<any>({ defaultValues: createBuildingStore.data });

  const handleCreate = form.handleSubmit((data) => {
    createBuildingStore.submit(data);
  });

  return (
    <Form form={form}>
      <Grid container>
        <div className={classes.contentCard}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField name="name" label="ชื่อ" RHFInputProps={{ rules: { required: true } }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                TextFieldProps={{ label: 'อยู่ภายใต้' }}
                name="parent.id"
                resource="buildings"
                defaultValue={createBuildingStore.data?.parentId}
                getOptionLabel={(data: any) =>
                  data ? (data.parent ? data.buildingFullname : `${data.name} (อาคาร)`) : ''
                }
                field="parentId"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                TextFieldProps={{ label: 'องค์กร' }}
                name="group.id"
                resource="client-groups"
                field="name"
                defaultValue={createBuildingStore.data?.groupId}
                validationOptions={{ required: true }}
              />
            </Grid>
          </Grid>
          <LoadingButton
            onClick={handleCreate}
            color="primary"
            variant="contained"
            loading={createBuildingStore.creating}
          >
            บันทึก
          </LoadingButton>
        </div>
      </Grid>
    </Form>
  );
};

export default AdminEditBuildingFloorPage;
