import { observable, action, runInAction, computed } from 'mobx';

import { client } from '../api';
import { RootStore } from './RootStore';
import { auth, firebase } from '../firebase';
import Loadable from './Loadable';
import { User, UserRole } from '../models';

const USER_KEY = 'user/profile';

export class AuthStore {
  // Store backend user object
  @observable user = Loadable.create<User | null>(null);

  // Store Firebase user object
  @observable auth = Loadable.create<any>(null);
  @observable error = false;
  @observable authenticating: boolean = false;

  // Used for when user enter the page
  @observable verifying: boolean = false;

  constructor(private readonly rootStore: RootStore) {
    this.initUserFromLocalStore();
    this.initFirebase();
  }

  @action
  private initUserFromLocalStore() {
    const user = localStorage.getItem(USER_KEY);

    if (user) {
      runInAction(() => {
        const userData = JSON.parse(user);
        this.user.set(userData);
      });
    }
  }

  @action
  private async initFirebase() {
    this.verifying = true;
    firebase.auth().onAuthStateChanged(
      (user) =>
        runInAction('Firebase onAuthStateChanged Successfully', () => {
          if (user) {
            this.auth.set(user);
            this.fetchProfile();
          }
          this.verifying = false;
        }),
      (error) => {
        runInAction('Firebase onAuthStateChanged completed', () => {
          this.verifying = false;
        });
      }
    );
  }

  @action
  private async fetchProfile() {
    this.user.setLoading(true);

    try {
      const response = await client.get('/me');
      const data = response.data;

      runInAction(() => {
        this.user.set(data);
        this.user.setLoading(false);
        localStorage.setItem(USER_KEY, JSON.stringify(data));
      });

      return data;
    } catch (ex) {
      console.error(ex);
      runInAction(() => {
        this.user.setLoading(false);
      });
    }
  }

  @action
  public async login(credentials: { email: string; password: string }) {
    this.user.setLoading(true);
    this.error = false;
    try {
      const { email, password } = credentials;
      await auth.signInWithEmailAndPassword(email, password);
      runInAction('Login successfully', () => {
        this.error = true;
        this.user.setLoading(false);
        // this.rootStore.routingStore.replace('/');
        this.rootStore.notiStore.enqueueSnackbar('เข้าสู่ระบบสำเร็จ', { variant: 'success' });
      });
    } catch (ex) {
      runInAction('Login failed', () => {
        this.error = true;
        this.user.setLoading(false);
        this.rootStore.notiStore.enqueueSnackbar('ไม่สามารถเข้าสู่ระบบได้', { variant: 'error' });
      });
      console.error(ex);
    }
  }

  @action
  public async logout() {
    this.error = false;
    try {
      await auth.signOut();
      runInAction('Logout successfully', () => {
        this.user.set(null);
        this.auth.set(null);
        localStorage.removeItem(USER_KEY);
        // this.rootStore.routingStore.replace('/login');
      });
    } catch (ex) {
      runInAction('Logout failed', () => {
        this.error = true;
        this.user.setLoading(false);
      });
      console.error(ex);
    }
  }

  @computed
  get userType(): 'admin' | 'operation' | 'client' {
    const user = this.user.val() as User;
    return user?.type;
  }

  @computed
  get roles(): UserRole[] {
    const user = this.user.val() as User;
    return user?.roles;
  }

  @computed
  get isAuth(): boolean {
    return !!this.auth.val();
  }

  /**
   * เช็คกับ role.value
   * @example 'admin', 'operation'
   * @param roles
   */
  hasAnyRoles(...roles: string[]) {
    const user = this.user.val() as User;
    return roles.some((role) => user.roles.some((userRole) => userRole.value));
  }
}
