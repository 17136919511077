import { action, observable, runInAction } from 'mobx';
import moment from 'moment';

import Loadable from '../Loadable';
import { client } from '../../api';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

export class DashboardStore {
  /**
   * สรุปประจำเดือน
   */
  @observable data = Loadable.create(null);

  /**
   * งานคงค้าง
   */
  @observable overdueTasks = Loadable.create(null);

  /**
   * งานวันนี้
   */
  @observable todayTasks = Loadable.create(null);

  @action
  async fetchMonthlySummary() {
    if (this.data.isLoading()) return;
    this.data.setLoading(true);

    try {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      const { data } = await client.get(`transaction-summary?month=${month}&year=${year}`);
      this.data.set(data);
    } catch (ex) {
      runInAction('Fetch Dashboard failed', () => {
        console.error(ex);
      });
    }
  }

  @action
  async fetchOverdueTasks() {
    if (this.overdueTasks.isLoading()) return;
    this.overdueTasks.setLoading(true);

    try {
      const today = moment().format('YYYY-MM-DD');
      const queryString = RequestQueryBuilder.create({
        filter: [
          { field: 'date', operator: '$lt', value: today },
          { field: 'state', operator: '$eq', value: 'INCOMPLETE' }
        ],
        sort: [{ field: 'date', order: 'ASC' }],
        // limit: 10
      }).query();
      const { data: payload } = await client.get(`operation-tasks?${queryString}`);
      runInAction('Fetch Dashboard OverdueTasks successfully', () => {
        this.overdueTasks.set(payload.data);
      });
    } catch (ex) {
      runInAction('Fetch Dashboard OverdueTasks failed', () => {
        console.error(ex);
      });
    }
  }

  @action
  async fetchTodayTasks() {
    if (this.todayTasks.isLoading()) return;
    this.todayTasks.setLoading(true);

    try {
      const today = moment().format('YYYY-MM-DD');
      const queryString = RequestQueryBuilder.create({
        filter: [{ field: 'date', operator: '$eq', value: today }],
        sort: [{ field: 'date', order: 'ASC' }],
        // limit: 10
      }).query();
      const { data: payload } = await client.get(`operation-tasks?${queryString}`);
      runInAction('Fetch Dashboard TodayTasks successfully', () => {
        this.todayTasks.set(payload.data);
      });
    } catch (ex) {
      runInAction('Fetch Dashboard TodayTasks failed', () => {
        console.error(ex);
      });
    }
  }
}
