import React from 'react';
import { Box, Typography } from '@material-ui/core';

export interface TitleProps {
  title?: string | React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ title }) => {
  return (
    <Box padding={1} paddingLeft={2}>
      <Typography variant="h5" component="h5">
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
