import {
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import DescriptionIcon from '@material-ui/icons/Description';
import ApartmentIcon from '@material-ui/icons/Apartment';
import EventIcon from '@material-ui/icons/Event';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HistoryIcon from '@material-ui/icons/History';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../stores';
import { AdapterLink } from './RouterLink';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 71,
  },
  list: {
    width: 250,
  },
}));

const routing = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />,
    permissions: ['admin', 'client'],
  },
  {
    title: 'Dashboard (ประจำเดือน)',
    path: '/admin',
    icon: <DashboardIcon />,
    permissions: ['admin', 'client'],
  },
  {
    title: 'งานประจำวัน',
    path: '/daily-tasks',
    icon: <EventIcon />,
    permissions: ['admin', 'client', 'operation'],
  },
  {
    title: 'สรุปประจำเดือน',
    path: '/admin/monthly-summary-report',
    icon: <EventNoteIcon />,
    permissions: ['admin', 'client'],
  },
  {
    title: 'ประวัติการซื้อ',
    path: '/admin/transactions',
    icon: <HistoryIcon />,
    permissions: ['admin', 'client', 'operation'],
  },
  {
    title: 'จุดรับซื้อสินค้า',
    path: '/admin/clients',
    icon: <LocalParkingIcon />,
    permissions: ['admin', 'client', 'operation'],
  },
  {
    title: 'ตารางงาน',
    path: '/admin/schedules',
    icon: <DescriptionIcon />,
    permissions: ['admin', 'client', 'operation'],
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: <DashboardIcon />,
    permissions: ['admin', 'client', 'operation'],
  },
  {
    title: 'สินค้า',
    path: '/admin/items',
    icon: <DeleteSweepIcon />,
    permissions: ['admin'],
  },
  {
    title: 'ตึก / ชั้น',
    path: '/admin/buildings',
    icon: <ApartmentIcon />,
    permissions: ['admin'],
  },
  {
    title: 'ผู้ใช้',
    path: '/admin/users',
    icon: <PersonIcon />,
    permissions: ['admin'],
  },
  {
    title: 'ตั้งค่าระบบ',
    path: '/admin/system',
    icon: <SettingsIcon />,
    permissions: ['admin'],
  },
];

const AdminSidebar = () => {
  const classes = useStyles({});
  const { app, authStore } = useStores();

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    app.toggleSidebar();
  };

  const userType = (authStore?.user.val()?.type || '') as string;

  return (
    <div className={classes.root}>
      <Drawer open={app.sidebar} onClose={toggleDrawer(false)}>
        {/* <List style={{ padding: '4px 24px' }}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel>องค์กร</InputLabel>
            <Select label="องค์กร">
              <MenuItem value="a">โรงพยาบาลจุฬา</MenuItem>
              <MenuItem value="b">โรงพยาบาลทดลอง</MenuItem>
            </Select>
          </FormControl>
        </List> */}
        <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            {routing.map((route, index) => {
              if (!route.permissions!.includes(userType)) {
                return null;
              }

              return (
                <ListItem button key={route.title} component={AdapterLink} to={route.path}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title} />
                </ListItem>
              );
            })}
          </List>
          <Divider />
        </div>
      </Drawer>
    </div>
  );
};

export default observer(AdminSidebar);
