import { action, observable, runInAction } from 'mobx';
import { client } from '../api';
import Loadable from './Loadable';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import notiStore from './NotificationStore';

export class OperationTaskStore {
  public resource = '/operation-tasks';
  @observable clientId?: string;
  @observable operationTasks = Loadable.create<any[]>();
  @observable loadingCancel: boolean;
  @action
  setClientId(id?: string) {
    if (id && id !== this.clientId) {
      this.fetch(id);
      this.clientId = id;
    } else {
      this.clientId = undefined;
    }
  }

  @action
  async fetch(clientId: string) {
    this.operationTasks.setLoading(true);
    try {
      const queryString = RequestQueryBuilder.create({
        filter: [
          { field: 'clientId', operator: '$eq', value: clientId },
          { field: 'state', operator: '$eq', value: 'INCOMPLETE' }
        ]
      }).query();
      const { data } = await client.get(`${this.resource}?${queryString}`);
      runInAction(() => {
        this.operationTasks.set(data.data);
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
      });
    }
  }
  @action
  async cancelTask() {
    try {
      this.loadingCancel = true;
      const tasks = this.operationTasks.val() || [];
      const tasksId = tasks.map((task: any) => task.id);
      const promises = tasksId.map(id => client.patch(`/operation-tasks/${id}`, { state: 'CANCELLED' }));
      await Promise.all(promises);
      this.fetch(this.clientId || '');
      this.loadingCancel = false;
    } catch (error) {
      console.log(error);
    }
  }
}
