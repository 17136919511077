import {
  AppBar,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../stores';
import { AdapterLink } from './RouterLink';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 71,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  title: {
    color: '#fff',
  },
  toolbar: theme.mixins.toolbar,
  titleBar: {
    flexGrow: 1,
    overflowX: 'hidden',
  },
}));

const Header = () => {
  const classes = useStyles({});
  const { app, authStore } = useStores();

  const handleDrawer = () => {
    app.toggleSidebar();
  };

  if (!authStore.isAuth) return null;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.titleBar}>
            <Link component={AdapterLink} to="/" className={classes.title}>
              <Typography variant="h6" noWrap className={classes.content}>
                Missnightingale HealthCare
              </Typography>
            </Link>
          </div>
          {/* <div style={{ flexGrow: 1 }}>
            <FormControl variant="outlined" margin="dense" color="primary">
              <Select value="a">
                <MenuItem value="a">โรงพยาบาลจุฬา</MenuItem>
                <MenuItem value="b">โรงพยาบาลทดลอง</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          <div>
            <UserMenu />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default observer(Header);
