import { RouterStore } from 'mobx-react-router';

import { AdminStores } from './admin';
import { AppStore } from './AppStore';
import { ClientStore } from './ClientStore';
import { ItemStore } from './ItemStore';
import { OperationHomeStore } from './operation/OperationHomeStore';
import { TransactionStore } from './transaction/TransactionStore';
import { TransactionItemStore } from './transaction/TransactionItemStore';
import { AuthStore } from './AuthStore';
import { DashboardStore } from './admin/DashboardStore';
import { DailyPageStore } from './DailyPageStore';
import { ScheduleStore } from './ScheduleStore';
import { ClientGroupStore } from './ClientGroupStore';
import { BuildingStore } from './BuildingStore';
import { UomStore } from './uomStore';
import { CategoryStore } from './categoryStore';
import { OperationTaskStore } from './OperationTaskStore';
import { RoleStore } from './RoleStore';
import notiStore, { NotificationStore } from './NotificationStore';

export const routingStore = new RouterStore();

export class RootStore {
  public readonly app: AppStore;
  public readonly scheduleStore: ScheduleStore;
  public readonly myOperation: OperationHomeStore;
  public readonly admin: AdminStores;
  public readonly client: ClientStore;
  public readonly transactionStore: TransactionStore;
  public readonly transactionItemStore: TransactionItemStore;
  public readonly routingStore: RouterStore;
  public readonly itemStore: ItemStore;
  public readonly authStore: AuthStore;
  public readonly dashboardStore: DashboardStore;
  public readonly dailyPageStore: DailyPageStore;
  public readonly clientGroupStore: ClientGroupStore;
  public readonly buildingStore: BuildingStore;
  public readonly uomStore: UomStore;
  public readonly categoryStore: CategoryStore;
  public readonly operationTaskStore: OperationTaskStore;
  public readonly roleStore: RoleStore;
  public readonly notiStore: NotificationStore;

  constructor() {
    this.app = new AppStore();
    this.scheduleStore = new ScheduleStore();
    this.authStore = new AuthStore(this);
    this.myOperation = new OperationHomeStore();
    this.admin = new AdminStores();
    this.client = new ClientStore();
    this.routingStore = routingStore;
    this.transactionItemStore = new TransactionItemStore();
    this.transactionStore = new TransactionStore(this);
    this.itemStore = new ItemStore();
    this.dashboardStore = new DashboardStore();
    this.dailyPageStore = new DailyPageStore();
    this.clientGroupStore = new ClientGroupStore();
    this.buildingStore = new BuildingStore();
    this.uomStore = new UomStore();
    this.categoryStore = new CategoryStore();
    this.operationTaskStore = new OperationTaskStore();
    this.roleStore = new RoleStore();
    this.notiStore = notiStore;
  }
}
