import { action, runInAction } from 'mobx';

import { client } from '../../api';
import { User } from '../../models';
import { AdminCrudStore } from './admin-crud-store';

export class UserEditStore extends AdminCrudStore<User> {
  resource = 'users';

  @action
  async uploadImage(file: File) {
    if (this.fetching) return;
    this.state = 'SUBMITTING';
    const formData = new FormData();
    formData.append('file', file);
    await client.post(`/users/${this.data.id}`, formData);
  }

  @action
  async updatePermissions(roleIds: string[]) {
    if (this.fetching) return;
    this.state = 'SUBMITTING';

    try {
      const { data } = await client.post(`/users/${this.data.id}/roles`, { roleIds });
      runInAction(() => {
        this.state = 'SUCCESS';
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        this.state = 'FAIL';
      });
    }
  }

  @action
  async changePassword(newPassword: string) {
    if (this.fetching) return;
    this.state = 'SUBMITTING';

    try {
      const { data } = await client.put(`/users/${this.data.id}/change-password`, { newPassword });
      runInAction(() => {
        this.state = 'SUCCESS';
        this.stores.notiStore.enqueueSnackbar(this.notificationText.SUBMIT_SUCCESSFULLY_TEXT, {
          variant: 'success'
        });
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        this.state = 'FAIL';
        this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT, {
          variant: 'error'
        });
      });
    }
  }
}
