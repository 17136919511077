import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

client.interceptors.request.use(async (config) => {
  const currentUser = firebase.auth().currentUser;
  const token = await currentUser?.getIdToken();
  config.headers['Authorization'] = `Bearer ${token}`;
  // TODO: [CR] เพิ่ม clientGroupId
  return config;
});
