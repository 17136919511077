import { ItemEditStore } from './item-edit-store';
import { ClientEditStore } from './client-edit-store';
import { UserEditStore } from './user-edit-store';
import { CreateClientStore } from './client-add-store';
import { ItemAddStore } from './item-add-store';
import { RouterStore } from 'mobx-react-router';
import { routingStore } from '../RootStore';
import { CreateUserStore } from './user-add-store';
import notiStore, { NotificationStore } from '../NotificationStore';
import { CreateBuildingStore } from './building-add-store';

export class AdminStores {
  public itemEditStore: ItemEditStore;
  public createClientStore: CreateClientStore;
  public createBuildingStore: CreateBuildingStore;
  public clientEditStore: ClientEditStore;
  public userEditStore: UserEditStore;
  public itemAddStore: ItemAddStore;
  public createUserStore: CreateUserStore;
  public readonly routingStore: RouterStore;
  public readonly notiStore: NotificationStore;

  constructor() {
    this.itemEditStore = new ItemEditStore(this);
    this.clientEditStore = new ClientEditStore(this);
    this.userEditStore = new UserEditStore(this);
    this.createClientStore = new CreateClientStore(this);
    this.createBuildingStore = new CreateBuildingStore(this);
    this.itemAddStore = new ItemAddStore(this);
    this.createUserStore = new CreateUserStore(this);
    this.routingStore = routingStore;
    this.notiStore = notiStore;
  }
}
