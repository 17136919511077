import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import MaterialTable, { MaterialTableProps, Action as MaterialTableAction, Query, Filter } from 'material-table';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores';
import { client } from '../../api';
import { RequestQueryBuilder, QuerySortOperator, QueryFilter } from '@nestjsx/crud-request';

type Action = MaterialTableAction<object>;

interface Props extends Omit<MaterialTableProps<object>, 'data'> {
  resource: string;
  crudActions?: ('add' | 'update' | 'delete')[];
  extendActions?: Action[];
  filtersMapping?: MappingFilter[];
}

type MappingFilter = {
  field: string;
  to?: string | string[];
};

const defaultOptions = {
  search: false,
  actionsColumnIndex: 1000,
  pageSize: 20,
  pageSizeOptions: [20, 30, 50],
  filtering: true,
};

const DataTable = ({
  resource,
  actions = [],
  extendActions = [],
  crudActions = [],
  isLoading,
  options,
  filtersMapping,
  tableRef: ref,
  ...tableProps
}: Props) => {
  const { authStore, notiStore } = useStores();
  const [loading, setLoading] = useState(false);
  const tableRef = useRef<any>(React.createRef());
  const { push } = useHistory();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ref.current = tableRef.current;
  }, [ref]);

  /**
   * Default actions property
   */
  const defaultActions: { [key: string]: Action } = {
    update: {
      icon: 'edit',
      tooltip: 'แก้ไขข้อมูล',
      onClick: (event: any, rowData: any) => {
        push(`/admin/${resource}/${rowData.id}`);
      },
      hidden: authStore.userType !== 'admin',
    },
    delete: {
      icon: 'delete',
      iconProps: {
        color: 'secondary',
      },
      tooltip: 'ลบข้อมูล',
      onClick: async (event: any, rowData: any) => {
        setLoading(true);
        try {
          await client.delete(`/${resource}/${rowData.id}`);
          tableRef.current.onQueryChange();
          notiStore.enqueueSnackbar('ลบสำเร็จ', {
            variant: 'success',
          });
        } catch (error) {
          tableRef.current.onQueryChange();
          notiStore.enqueueSnackbar('ลบไม่สำเร็จ', {
            variant: 'error',
          });
        } finally {
          setLoading(false);
        }
      },
      hidden: authStore.userType !== 'admin',
    },
    add: {
      icon: 'add',
      tooltip: 'เพิ่มข้อมูล',
      isFreeAction: true,
      onClick: (event: any, rowData: any) => {
        push(`/admin/${resource}/add`);
      },
      hidden: authStore.userType !== 'admin',
    },
  };

  const actionsProp = useMemo(
    () =>
      crudActions
        .map((actionKey) => defaultActions[actionKey])
        .concat(actions as Action[])
        .concat(extendActions),
    [actions, crudActions, defaultActions, extendActions]
  );

  /**
   * Data property
   */
  const data = (query: Query<object>) =>
    new Promise(async (resolve, reject) => {
      const builder = RequestQueryBuilder.create({
        limit: query.pageSize,
        page: query.page + 1,
      });

      if (query.orderBy && query.orderDirection) {
        const field = (query.orderBy.field as any) as string;
        const order: QuerySortOperator = (query.orderDirection.toUpperCase() as any) as QuerySortOperator;
        builder.sortBy({ field, order });
      }

      if (query.filters) {
        const filters = query.filters.reduce<QueryFilter[]>(processFilter(filtersMapping), []);
        builder.setFilter(filters);
      }

      const queryString = builder.query();
      const response = await client.get(`/${resource}?${queryString}`);

      resolve({
        data: response.data.data,
        page: response.data.page - 1,
        totalCount: response.data.total,
      });
    });

  return (
    <MaterialTable
      options={{
        ...defaultOptions,
        ...options,
      }}
      data={data as any}
      tableRef={tableRef}
      isLoading={loading || isLoading}
      actions={actionsProp}
      {...tableProps}
    />
  );
};

export default observer(DataTable);

function processFilter(filtersMapping: MappingFilter[] | undefined) {
  return (prev: QueryFilter[], filter: Filter<any>) => {
    const getMappingField = (field: string) => {
      const mapping = filtersMapping?.find((record: any) => record.field === field);
      return mapping ? mapping : field;
    };

    if (filter.value instanceof Array && filter.value.length === 0) {
      return prev;
    }

    const mapping = getMappingField(filter.column.field as string);

    const filterField: Partial<QueryFilter> = {
      field: filter.column.field as string,
    };
    if (typeof filter.value === 'string' && ['checked', 'unchecked'].includes(filter.value)) {
      filterField.operator = '$eq';
      filterField.value = filter.value === 'checked';
    } else if (typeof filter.value === 'string') {
      filterField.operator = '$cont';
      filterField.value = filter.value;
    } else if (filter.value instanceof Array) {
      filterField.operator = '$in';
      filterField.value = filter.value;
    } else {
      filterField.operator = '$eq';
      filterField.value = filter.value;
    }

    if (!mapping || typeof get(mapping, 'to') !== 'object') {
      return prev.concat(filterField as QueryFilter);
    }

    const filters = ((mapping as MappingFilter)!.to! as string[]).map<QueryFilter>((field) => ({
      field: field,
      operator: '$cont',
      value: filter.value,
    }));

    return prev.concat(...filters);
  };
}
