import { rootContext } from './contexts';
import React, { useContext } from 'react';
import { TransactionConfirmStoreContext } from './transaction/TransactionConfirmStore';

export const useStores = () => React.useContext(rootContext);

export const useAuth = () => {
  const { authStore } = useStores();
  return authStore;
};

export const useMyOperation = () => {
  const rootStore = useStores();
  return rootStore.myOperation;
};

export const useApp = () => {
  const rootStore = useStores();
  return rootStore.app;
};

export const useAdmin = () => {
  const rootStore = useStores();
  return rootStore.admin;
};

export const useClient = () => {
  const rootStore = useStores();
  return rootStore.client;
};

export const useTransaction = () => {
  const rootStore = useStores();
  return rootStore.transactionStore;
};

export const useTransactionItem = () => {
  const rootStore = useStores();
  return rootStore.transactionItemStore;
};

export const useItem = () => {
  const rootStore = useStores();
  return rootStore.itemStore;
};

export const useSchedule = () => {
  const rootStore = useStores();
  return rootStore.scheduleStore;
};
export const useTransactionConfirm = () => {
  return useContext(TransactionConfirmStoreContext);
};
export const useClientGroup = () => {
  const rootStore = useStores();
  return rootStore.clientGroupStore;
};

export const useBuilding = () => {
  const rootStore = useStores();
  return rootStore.buildingStore;
};

export const useUom = () => {
  const rootStore = useStores();
  return rootStore.uomStore;
};

export const useCategory = () => {
  const rootStore = useStores();
  return rootStore.categoryStore;
};
export const useOperationTask = () => {
  const rootStore = useStores();
  return rootStore.operationTaskStore;
};
