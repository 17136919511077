import { get } from 'lodash';
import bigDecimal from 'js-big-decimal';

/**
 * Used for submit form.
 * normalize data before send to API.
 */
export function normalize(data: any) {
  if (Array.isArray(data)) return data;
  if (typeof data === 'object') {
    const keys = Object.keys(data);
    return keys.reduce((prev, key) => {
      if (
        typeof prev[key] === 'object' &&
        (Object.keys(prev[key]).length === 0 || (prev[key].hasOwnProperty('id') && !prev[key]['id']))
      ) {
        prev[key] = undefined;
      }
      return prev;
    }, data);
  }

  return data;
}

export const deeppick = (object: any, paths: string[]) => paths.map((path) => get(object, path));

/**
 * Round down 2 places
 * @param n
 * @returns
 */
export function roundDown(n: number | string): string {
  return bigDecimal.round(n, 2, bigDecimal.RoundingModes.DOWN);
}
