import { FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { client } from '../../api';
import ContentCard from '../../components/ContentCard';
import LoadingButton from '../../components/LoadingButton';
import { Autocomplete } from '../../Form';
import Form, { TextField, Select } from '@aginix/mui-react-hook-form-input';

const operationQuerystring = RequestQueryBuilder.create({
  filter: [
    {
      field: 'type',
      operator: '$eq',
      value: 'operation',
    },
  ],
}).query();

const scheduleQuerystring = RequestQueryBuilder.create({
  filter: [
    {
      field: 'type',
      operator: '$eq',
      value: 'operation',
    },
  ],
}).query();

async function createScheduleFn(inputData: any) {
  const { data } = await client.post(`/schedules`, {
    dayOfWeek: inputData.dayOfWeek,
    clientId: inputData.clientId,
    startTime: inputData.startTime,
    endTime: inputData.endTime,
  });
  return data;
}

async function createScheduleUserFn(inputData: any) {
  const { data } = await client.post(`/schedule-users`, {
    scheduleId: inputData.scheduleId,
    userId: inputData.userId,
  });
  return data;
}

const AddScheduleForm = () => {
  const form = useForm<any>({});
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [createSchedule, { status: scheduleStatus }] = useMutation(createScheduleFn);
  const [createScheduleUser, { status: scheduleUserStatus }] = useMutation(createScheduleUserFn);
  const isFetching = scheduleStatus === 'loading' || scheduleUserStatus === 'loading';

  const handleSubmit = form.handleSubmit(async (formData: any) => {
    console.log(formData);
    const data = await createSchedule(formData);

    formData.scheduleId = data.id;
    await createScheduleUser(formData);

    enqueueSnackbar('Add schedule successfully');
    push('/admin/schedules');
  });

  return (
    <ContentCard title="เพิ่มตารางงาน">
      <div style={{ padding: 24 }}>
        <Form form={form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                name="clientId"
                resource="clients"
                TextFieldProps={{ label: 'จุดรับซื้อ' }}
                field="name"
                filterOptionsBy={['name', 'building.buildingFullname']}
                getOptionLabel={(option: any) => `${option.name} ${option.building?.buildingFullname || ''}`}
                validationOptions={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                name="userId"
                resource="users"
                field="name"
                filterOptionsBy={['name']}
                TextFieldProps={{ label: 'เจ้าหน้าที่ผู้รับผิดชอบ' }}
                queryString={operationQuerystring}
                getOptionLabel={(option: any) => `${option.name}`}
                validationOptions={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl variant="outlined" size="small" fullWidth error={!!form.errors!['dayOfWeek']}>
                <InputLabel id="dayOfWeek">วัน</InputLabel>
                <Select
                  name="dayOfWeek"
                  labelId="dayOfWeek"
                  id="dayOfWeek-select"
                  RHFInputProps={{ rules: { required: true } }}
                >
                  <MenuItem value={1}>วันจันทร์</MenuItem>
                  <MenuItem value={2}>วันอังคาร</MenuItem>
                  <MenuItem value={3}>วันพุธ</MenuItem>
                  <MenuItem value={4}>วันพฤหัสบดี</MenuItem>
                  <MenuItem value={5}>วันศุกร์</MenuItem>
                  <MenuItem value={6}>วันเสาร์</MenuItem>
                  <MenuItem value={0}>วันอาทิตย์</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name="startTime"
                label="เวลาเริ่ม"
                placeholder="Ex. 07:00"
                RHFInputProps={{ rules: { required: true } }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name="endTime"
                label="เวลาสิ้นสุด"
                placeholder="Ex. 08:00"
                RHFInputProps={{ rules: { required: true } }}
              />
            </Grid>
            <Grid item md={12}>
              <LoadingButton loading={isFetching} variant="contained" color="primary" onClick={handleSubmit}>
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      </div>
    </ContentCard>
  );
};

const AdminAddSchedulePage = () => {
  return (
    <Fragment>
      <AddScheduleForm />
    </Fragment>
  );
};

export default AdminAddSchedulePage;
