import { action, observable, runInAction } from 'mobx';

import { client } from '../api';
import Loadable from './Loadable';

export class ClientGroupStore {
  @observable public clientGroup = Loadable.create<any[]>();
  @observable search: string | undefined;
  @action
  async fetch() {
    if (this.clientGroup.val()) return;
    try {
      this.clientGroup.setLoading(true);
      const { data: payload } = await client.get(`/client-groups`);

      runInAction('fetchClientGroup', () => {
        this.clientGroup.set(payload.data);
      });
    } catch (error) {
      runInAction('fetchClientGroupFailed', () => {
        console.error(error);
        this.clientGroup.setLoading(false);
      });
    }
  }
  async setSearch(search: string | undefined) {
    if (this.clientGroup.isLoading()) return;
    try {
      runInAction('setSearch', () => {
        this.search = search;
      });
    } catch (error) {
      runInAction('fetchClientGroupFailed', () => {
        console.error(error);
        this.clientGroup.setLoading(false);
      });
    }
  }
}
