import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';

import ContentCard from '../../components/ContentCard';
import LoadingButton from '../../components/LoadingButton';
import { useAdmin } from '../../stores';
import { Autocomplete } from '../../Form';
import Form, { TextField } from '@aginix/mui-react-hook-form-input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    image: {
      width: '100%',
    },
    contentCard: {
      width: '100%',
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
  })
);

const AdminAddBuildingFloorPage = () => {
  const classes = useStyles();
  const { createBuildingStore } = useAdmin();
  const form = useForm<any>();
  const handleCreate = form.handleSubmit((data) => {
    createBuildingStore.create(data);
  });

  return (
    <ContentCard title="ตึก / ชั้น">
      <Form form={form}>
        <Grid container>
          <div className={classes.contentCard}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField name="name" label="ชื่อ" required RHFInputProps={{ rules: { required: true } }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  TextFieldProps={{ label: 'อยู่ภายใต้' }}
                  name="parentId"
                  resource="buildings"
                  getOptionLabel={(data: any) => (data.parent ? data.buildingFullname : `${data.name} (อาคาร)`)}
                  field="buildingFullname"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  TextFieldProps={{ label: 'องค์กร' }}
                  name="group.id"
                  resource="client-groups"
                  field="name"
                  validationOptions={{ required: true }}
                />
              </Grid>
            </Grid>
            <Box marginBottom={2}>
              <Typography component="p" variant="body2" gutterBottom>
                อยู่ภายใต้ตึก/อาคาร
              </Typography>
            </Box>
            <LoadingButton
              onClick={handleCreate}
              color="primary"
              variant="contained"
              loading={createBuildingStore.creating}
            >
              สร้าง
            </LoadingButton>
          </div>
        </Grid>
      </Form>
    </ContentCard>
  );
};

export default observer(AdminAddBuildingFloorPage);
