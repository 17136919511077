import { action, runInAction, computed } from 'mobx';
import { client } from '../../api';
import { AdminCrudStore } from './admin-crud-store';

export class ItemEditStore extends AdminCrudStore<any> {
  resource: string = '/items';

  @action
  async uploadImage(file: File) {
    try {
      if (this.fetching) return;
      this.state = 'UPLOADIMAGE';
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await client.post(`/items/${this.data.id}/upload-image`, formData);

      runInAction(() => {
        this.data = data;
        this.state = 'SUCCESS';
      });
    } catch (ex) {
      runInAction(() => {
        this.state = 'FAIL';
      });
    }
  }
  @computed
  get uploadingImage(): boolean {
    return this.state === 'UPLOADIMAGE';
  }
}
