import { observable, action, computed, runInAction } from 'mobx';
import { client } from '../api';

export abstract class BaseListStore<T = any> {
  abstract resource: string;
  @observable list: T;
  @observable state: 'SUCCESS' | 'FAIL' | 'FETCHING' | 'SUBMITTING';

  @action
  async fetch() {
    if (this.fetching || this.list) return;
    this.state = 'FETCHING';
    try {
      const { data } = await client.get(`${this.resource}`);
      runInAction(() => {
        this.list = data;
        this.state = 'SUCCESS';
      });
    } catch (ex) {
      runInAction(() => {
        this.state = 'FAIL';
        // this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT);
      });
    }
  }

  @computed
  get fetching(): boolean {
    return this.state === 'FETCHING';
  }

  @computed
  get submitting(): boolean {
    return this.state === 'SUBMITTING';
  }
}
