import { Box, Container, Theme } from '@material-ui/core';
import React, { Fragment } from 'react';

import AdminSidebar from './AdminSidebar';
import Header from './Header';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: `calc(100vh - 173px)`,
  },
}));

const MainLayout: React.FC<{}> = ({ children }) => {
  const classes = useStyles({});
  return (
    <Fragment>
      <AdminSidebar />
      <Header />
      <div className={classes.container}>
        <Box pt={4} pb={4}>
          <Container children={children as any} />
        </Box>
      </div>
    </Fragment>
  );
};

export default MainLayout;
