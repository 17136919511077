import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700
  },
  background: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    color: 'white'
  }
}));
export const Footer = () => {
  const classes = useStyles({});
  return (
    <div className={classes.background}>
      <Box p={3}>
        <Typography className={classes.bold} variant="body1" align="center">
          64/36 หมู่ 7 ตำบลเสม็ด อำเภอเมืองชลบุรี จังหวัดชลบุรี 20130
        </Typography>
        <Typography variant="body1" align="center">
          © {moment().year()} by Missnightingale HealthCare
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
