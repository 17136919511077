import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { action, runInAction } from 'mobx';

import { client } from '../../api';
import { BaseListStore } from '../base-list-store';
import Loadable from '../Loadable';

interface MyOperationTask {
  overdue: any[];
  today: any[];
}

export class OperationHomeStore extends BaseListStore<MyOperationTask> {
  resource: string = 'me/operation-tasks';
  public allTasks = Loadable.create([]);

  @action
  async fetchByQueryBuilder(builder: RequestQueryBuilder) {
    if (this.allTasks.isLoading()) return;

    this.allTasks.setLoading(true);
    try {
      const queryString = builder.query();
      const { data: payload } = await client.get(`operation-tasks?${queryString}`);
      runInAction('fetchAllTasksSuccessfully', () => {
        this.allTasks.set(payload.data);
        this.allTasks.setLoading(false);
      });
    } catch (ex) {
      runInAction('fetchAllTasksFailed', () => {
        console.error(ex);
      });
    }
  }
}
