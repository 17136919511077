import { action, extendObservable, observable, runInAction } from 'mobx';
import { SnackbarMessage, OptionsObject } from 'notistack';

type NotificationType = {
  message: SnackbarMessage;
  options?: OptionsObject;
  key: number;
};

// export const NotificationStore = function(this: any) {
//   const objects = {
//     notifications: [],

// enqueueSnackbar: action((message: SnackbarMessage, options?: OptionsObject) => {
//   (objects.notifications as any).push({
//     key: new Date().getTime() + Math.random(),
//     message,
//     options
//   });
// }),

// removeSnackbar: action((message: SnackbarMessage, options?: OptionsObject) => {
//   (objects.notifications as any).remove(message);
// })
//   };
//   extendObservable(this);
// };

export class NotificationStore {
  @observable notifications: Array<{ key: number; message: SnackbarMessage; options?: OptionsObject }> = [];

  @action
  enqueueSnackbar(message: SnackbarMessage, options?: OptionsObject) {
    runInAction(() => {
      this.notifications = this.notifications.concat({
        key: new Date().getTime() + Math.random(),
        message,
        options
      });
    });
  }

  @action
  removeSnackbar(message: SnackbarMessage, options?: OptionsObject) {
    runInAction(() => {
      this.notifications = this.notifications.filter(_ => _.message !== message);
    });
  }
}

const notiStore = new NotificationStore();
export default notiStore;
