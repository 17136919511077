import { action, computed, observable, runInAction } from 'mobx';
import bigDecimal from 'js-big-decimal';
import Loadable from '../Loadable';
import { client } from '../../api';
import { TransactionCreateDto } from '../../models';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { RootStore } from '../RootStore';
import { roundDown } from '../../utils/data';

export class TransactionStore {
  @observable public transactionId?: string;
  @observable public transaction = Loadable.create<any>();
  @observable public transactionList = Loadable.create<any>([]);
  @observable public paymentConfirmation = Loadable.create<any>();

  // Form confirm

  constructor(public readonly root: RootStore) {}

  @computed
  get itemsStore() {
    return this.root.transactionItemStore;
  }

  @action
  setTransactionId(transactionId?: string) {
    if (transactionId && transactionId !== this.transactionId) {
      this.transactionId = transactionId;
      this.itemsStore.transactionId = transactionId;
      this.fetch();
    } else if (!transactionId) {
      this.transactionId = undefined;
      this.itemsStore.transactionId = undefined;
      this.transaction.set(undefined);
    }
  }

  @action
  async fetch() {
    this.transaction.setLoading(true);
    const { data } = await client.get(`/transactions/${this.transactionId}`);

    runInAction('fetchTransactionById', () => {
      this.transaction.set(data);
    });
  }

  @action
  async fetchListByClientId(clientId: string) {
    this.transactionList.setLoading(true);

    const queryString = RequestQueryBuilder.create({
      sort: [{ field: 'modifiedAt', order: 'DESC' }],
      filter: [{ field: 'clientId', operator: '$eq', value: clientId }],
    }).query();
    const { data: payload } = await client.get(`/transactions?${queryString}`);

    runInAction('fetchTransactionById', () => {
      this.transactionList.set(payload.data);
    });
  }

  @action
  async create(dto: TransactionCreateDto) {
    this.transaction.setLoading(true);
    const { data } = await client.post<any>(`/transactions`, dto);

    runInAction('fetchTransactionListByClientId', () => {
      this.transactionId = data.id;
      this.itemsStore.transactionId = data.id;
      this.transaction.set(data);

      // routing
      this.root.routingStore.push(`/transaction/${this.transactionId}`, false);
    });
  }

  @action
  async cancel() {
    this.transaction.setLoading(true);
    await client.post(`/transactions/${this.transactionId}/cancel`);

    runInAction('cancelTransactionSuccess', () => {
      this.transaction.set(null);
    });
  }

  @action
  async submit(dto: { file: string; isPaid?: boolean; remark?: string }) {
    this.transaction.setLoading(true);

    const formData = new FormData();
    formData.append('file', dto.file);
    formData.append('isPaid', dto.isPaid ? String(dto.isPaid) : 'false');
    formData.append('remark', dto.remark ? String(dto.remark) : '');

    const { data } = await client.post(`/transactions/${this.transactionId}/submit`, formData);

    runInAction('submitTransactionSuccess', () => {
      this.transaction.set(data);
      this.root.routingStore.replace(`/transaction/${this.transactionId}`);
    });
  }

  @action
  async query(builder: RequestQueryBuilder) {
    this.transactionList.setLoading(true);

    const queryString = builder.query();
    const { data: payload } = await client.get(`/transactions?${queryString}`);

    runInAction('queryTransactionList', () => {
      this.transactionList.set(payload.data);
    });
  }

  @action
  async confirmPayment() {
    this.paymentConfirmation.setLoading(true);

    try {
      const { data } = await client.post(`/transactions/${this.transactionId}/confirm-payment`);

      runInAction('confirmPaymentSuccessfully', () => {
        this.transaction.set(data);
        this.paymentConfirmation.setLoading(false);
      });
    } catch (ex) {
      runInAction('confirmPaymentFailed', () => {
        console.error(ex);
        this.paymentConfirmation.setLoading(false);
      });
    }
  }

  @computed
  get totalWeight(): number {
    const items = this.itemsStore.items.val();
    if (!items) return 0;
    return items.reduce((pWeight, item: any) => pWeight + item.weight, 0);
  }

  @computed
  get totalItems(): number {
    const items = this.itemsStore.items.val();
    if (!items) return 0;
    return items.length;
  }

  @computed
  get totalPrice(): number {
    const items = this.itemsStore.items.val();
    if (!items) return 0;
    return items.reduce(
      (pPrice, item: any) =>
        parseFloat(bigDecimal.add(pPrice, roundDown(bigDecimal.multiply(item.price, item.weight)))),
      0
    );
  }

  @computed
  get totalPriceOfTransactionList(): number {
    const transactionList = this.transactionList.val();
    return transactionList
      .map((data: any) => data.totalPrice)
      .reduce((prevPrice: any, nextPrice: any) => prevPrice + nextPrice, 0);
  }
  @computed
  get paidPriceOfTransactionList(): number {
    const transactionList = this.transactionList.val();
    return transactionList
      .filter((data: any) => data.isPaid === true)
      .map((data: any) => data.totalPrice)
      .reduce((prevPrice: any, nextPrice: any) => prevPrice + nextPrice, 0);
  }
}
