import { action, observable, runInAction } from 'mobx';
import { client } from '../api';
import Loadable from './Loadable';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

export class ClientStore<T = any> {
  public resource = '/clients';
  @observable clientId?: string;
  @observable client = Loadable.create<any>();
  @observable summary = Loadable.create<any>();
  @observable dailyItemSummary = Loadable.create<any>([]);
  @observable clientList = Loadable.create<any>();
  @observable public clientBySearch = Loadable.create<any[]>();
  @observable search: string | undefined;
  @action
  setClientId(id?: string) {
    if (id && id !== this.clientId) {
      this.fetch(id);
      this.fetchSummary(id);
      this.clientId = id;
    } else {
      this.clientId = undefined;
    }
  }

  @action
  async fetch(clientId: string) {
    this.client.setLoading(true);
    try {
      const { data } = await client.get(`${this.resource}/${clientId}`);
      runInAction(() => {
        this.client.set(data);
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        // this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT);
      });
    }
  }

  @action
  async fetchSummary(clientId: string) {
    this.summary.setLoading(true);
    try {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();

      const { data } = await client.get(`${this.resource}/${clientId}/summary?month=${month}&year=${year}`);
      runInAction(() => {
        this.summary.set(data);
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        // this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT);
      });
    }
  }

  @action
  async fetchList(searchString: string) {
    if (this.clientList.isLoading()) {
      return;
    }

    this.clientList.setLoading(true);

    try {
      const queryString = RequestQueryBuilder.create({
        sort: [{ field: 'name', order: 'ASC' }],
        search: {
          $or: [{ name: { $contL: searchString } }, { 'building.name': { $contL: searchString } }]
        },
        limit: 12
      }).query();
      const { data: payload } = await client.get(`/clients?${queryString}`);
      runInAction('fetchClientListSuccess', () => {
        this.clientList.set(payload.data);
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  @action
  async fetchDailyItemsSummary(date: string) {
    if (this.dailyItemSummary.isLoading()) {
      return;
    }

    this.dailyItemSummary.setLoading(true);

    try {
      const { data } = await client.get(`/clients/${this.clientId}/daily-items-summary?date=${date}`);
      runInAction('fetchDailyItemsSummarySuccessfully', () => {
        this.dailyItemSummary.set(data);
      });
    } catch (ex) {
      runInAction('fetchDailyItemsSummaryFailed', () => {
        console.error(ex);
        this.dailyItemSummary.setLoading(false);
      });
    }
  }

  @action
  async searchClient() {
    if (this.clientBySearch.val()) return;
    try {
      this.clientBySearch.setLoading(true);
      const { data: payload } = await client.get(`/clients`);

      runInAction('fetchclientBySearch', () => {
        this.clientBySearch.set(payload.data);
      });
    } catch (error) {
      runInAction('fetchclientBySearchFailed', () => {
        console.error(error);
        this.clientBySearch.setLoading(false);
      });
    }
  }
  async setSearch(search: string | undefined) {
    if (this.clientBySearch.isLoading()) return;
    try {
      runInAction('setSearch', () => {
        this.search = search;
      });
    } catch (error) {
      runInAction('fetchclientBySearchFailed', () => {
        console.error(error);
        this.clientBySearch.setLoading(false);
      });
    }
  }
}
