import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { action, observable, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { client } from '../api';
import Loadable from './Loadable';

export class ItemStore {
  @observable public items = Loadable.create<any[]>();

  @action
  async fetch() {
    if (this.items.val()) return;

    this.items.setLoading(true);
    const queryString = RequestQueryBuilder.create({
      filter: [{ field: 'active', operator: '$eq', value: true }]
    }).query();
    const { data: payload } = await client.get(`/items?${queryString}`);

    runInAction('fetchItemList', () => {
      this.items.set(payload.data);
    });
  }

  findById = computedFn((itemId: string) => {
    const items = this.items.val();
    if (!items) {
      return undefined;
    }
    return items.find((item: any) => item.id === itemId);
  });
}
