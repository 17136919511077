import { action, observable, runInAction } from 'mobx';
import { client } from '../api';
import Loadable from './Loadable';

export class ScheduleStore<T = any> {
  public resource = '/schedules';
  @observable schedule = Loadable.create<any>();

  @action
  async fetch(dayOfWeek: number) {
    this.schedule.setLoading(true);
    try {
      const { data } = await client.get(this.resource);
      const filterData = data.data.filter((data: any) => data.dayOfWeek === dayOfWeek);
      runInAction(() => {
        this.schedule.set(filterData);
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        // this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT);
      });
    }
  }
}
