import React from 'react';

import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  children?: React.ReactElement | string | number;
  component?: string;
}

const LoadingButton: React.FC<ButtonProps> = ({ disabled, loading, children, ...props }) => {
  const _disabled = loading || disabled;
  return (
    <Button color="primary" variant="contained" disabled={_disabled} {...props}>
      {loading && <CircularProgress size={20} />} {children}
    </Button>
  );
};

export default LoadingButton;
