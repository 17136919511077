import './App.css';

import React, { lazy, Suspense } from 'react';

import Footer from './components/Footer';
import MainLayout from './components/MainLayout';
import Notifier from './components/Notifier';
import TokenVerifier from './components/TokenVerifier';
import AdminAddBuildingFloorPage from './pages/admin/AdminAddBuildingFloorPage';
import AdminAddSchedulePage from './pages/admin/AdminAddSchedulePage';
import AdminBuildingFloorPage from './pages/admin/AdminBuildingFloorPage';
import AdminEditBuildingFloorPage from './pages/admin/AdminEditBuildingFloorPage';
import { AdminRoute, AuthenticatedRoute, OnlyAnonymousRoute } from './utils/react-router-util';
import { Route, Router, Switch } from 'react-router-dom';

import { Dashboard } from './pages/Dashboard';
import { history } from './custom-history';

const DailyTasksPage = lazy(() => import(/* webpackChunkName: "DailyTasksPage" */ './pages/DailyTasksPage'));
const DailyTasksOldPage = lazy(() => import(/* webpackChunkName: "DailyTasksPage" */ './pages/DailyTasksOldPage'));
const ClientCreateTransactionPage = lazy(
  () => import(/* webpackChunkName: "ClientCreateTransactionPage" */ './pages/ClientCreateTransactionPage')
);
const OperationHomePage = lazy(() => import(/* webpackChunkName: "OperationHomePage" */ './pages/OperationHomePage'));
const OperationTaskHomePage = lazy(
  () => import(/* webpackChunkName: "OpertationTasksPage" */ './pages/OperationTasksPage')
);
const QrReaderPage = lazy(() => import(/* webpackChunkName: "QrReaderPage" */ './pages/QrReaderPage'));
const AdminItemListPage = lazy(
  () => import(/* webpackChunkName: "AdminItemListPage" */ './pages/admin/AdminItemListPage')
);
const AdminClientListPage = lazy(
  () => import(/* webpackChunkName: "AdminClientListPage" */ './pages/admin/AdminClientListPage')
);

const ClientSearchPage = lazy(() => import(/* webpackChunkName: "ClientSearchPage" */ './pages/ClientSearchPage'));

const AdminEditClientPage = lazy(
  () => import(/* webpackChunkName: "AdminEditClientPage" */ './pages/admin/AdminEditClientPage')
);
const AdminHomePage = lazy(() => import(/* webpackChunkName: "AdminHomePage" */ './pages/admin/AdminHomePage'));
const AdminEditUserPage = lazy(
  () => import(/* webpackChunkName: "AdminEditUserPage" */ './pages/admin/AdminEditUserPage')
);

const AdminAddUserPage = lazy(
  () => import(/* webpackChunkName: "AdminAddUserPage" */ './pages/admin/AdminAddUserPage')
);
const AdminTransactionListPage = lazy(
  () => import(/* webpackChunkName: "AdminTransactionListPage" */ './pages/admin/AdminTransactionListPage')
);
const AdminSettingPage = lazy(
  () => import(/* webpackChunkName: "AdminSettingPage" */ './pages/admin/AdminSettingPage')
);
const AdminReportPage = lazy(() => import(/* webpackChunkName: "AdminReportPage" */ './pages/admin/AdminReportPage'));

const AdminTaskPage = lazy(() => import(/* webpackChunkName: "AdminTaskPage" */ './pages/admin/AdminTaskPage'));
const AdminUserListPage = lazy(
  () => import(/* webpackChunkName: "AdminUserListPage" */ './pages/admin/AdminUserListPage')
);
const NotFoundPage = lazy(() => import(/* webpackChunkName: "NotFoundPage" */ './pages/NotFoundPage'));
const ClientPage = lazy(() => import(/* webpackChunkName: "ClientPage" */ './pages/ClientPage'));
const TransactionDetailsPage = lazy(
  () => import(/* webpackChunkName: "TransactionDetailsPage" */ './pages/transaction/TransactionDetailsPage')
);
const TransactionConfirmPage = lazy(
  () => import(/* webpackChunkName: "TransactionConfirmPage" */ './pages/transaction/TransactionConfirmPage')
);
const AdminEditItemPage = lazy(
  () => import(/* webpackChunkName: "AdminEditItemPage" */ './pages/admin/AdminEditItemPage')
);
const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ './pages/LoginPage'));
const MonthlySummaryReportPage = lazy(
  () => import(/* webpackChunkName: "MonthlySummaryReportPage" */ './pages/MonthlySummaryReportPage')
);
const ReportListPage = lazy(() => import(/* webpackChunkName: "DashboardReportPage" */ './pages/ReportListPage'));
const ReportsPage = lazy(() => import(/* webpackChunkName: "DashboardReportPage" */ './pages/ReportsPage'));
const AdminClientAddPage = lazy(
  () => import(/* webpackChunkName: "AdminClientAddPage" */ './pages/admin/AdminClientAddPage')
);

const AdminSchedulePage = lazy(() => import(/* webpackChunkName: "AdminSchedulePage" */ './pages/ScheduleTablePage'));

const AdminItemAddPage = lazy(
  () => import(/* webpackChunkName: "AdminItemAddPage" */ './pages/admin/AdminItemAddPage')
);

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Notifier />
      <MainLayout>
        <TokenVerifier>
          <Suspense fallback={null}>
            <Switch>
              <AuthenticatedRoute path="/" exact component={OperationHomePage} />
              <Route path="/dashboard" exact component={Dashboard} />
              <AuthenticatedRoute path="/reports" exact component={ReportListPage} />
              <Route path="/reports/:id" exact component={ReportsPage} />
              <Route path="/search" exact component={ClientSearchPage} />
              {/* Operation Pages */}
              <Route path="/operation" exact component={OperationHomePage} />
              <Route path="/operation/tasks" exact component={OperationTaskHomePage} />

              {/* Transaction Pages */}
              <Route path="/transaction/:transactionId" exact component={TransactionDetailsPage} />
              <AuthenticatedRoute path="/transaction/:transactionId/confirm" exact component={TransactionConfirmPage} />
              <AuthenticatedRoute path="/daily-tasks" exact component={DailyTasksPage} />
              <AuthenticatedRoute path="/daily-tasks-old" exact component={DailyTasksOldPage} />
              <Route path="/qr-reader" exact component={QrReaderPage} />
              <AdminRoute path="/admin" exact component={AdminHomePage} />
              <AdminRoute path="/admin/items" exact component={AdminItemListPage} />
              <AdminRoute path="/admin/items/add" exact component={AdminItemAddPage} />
              <AuthenticatedRoute path="/admin/clients" exact component={AdminClientListPage} />
              <AuthenticatedRoute path="/admin/schedules" exact component={AdminSchedulePage} />
              <AdminRoute path="/admin/schedules/add" exact component={AdminAddSchedulePage} />
              <AdminRoute path="/admin/clients/add" exact component={AdminClientAddPage} />
              <AdminRoute path="/admin/clients/:clientId" exact component={AdminEditClientPage} />
              <AdminRoute path="/admin/users/add" exact component={AdminAddUserPage} />
              <AdminRoute path="/admin/users/:userId" exact component={AdminEditUserPage} />
              <AdminRoute path="/admin/buildings" exact component={AdminBuildingFloorPage} />
              <AdminRoute path="/admin/buildings/add" exact component={AdminAddBuildingFloorPage} />
              <AdminRoute path="/admin/buildings/:id" exact component={AdminEditBuildingFloorPage} />

              <Route path="/schedules" exact component={AdminSchedulePage} />
              <AuthenticatedRoute path="/admin/tasks" exact component={AdminTaskPage} />
              <AuthenticatedRoute path="/admin/monthly-summary-report" exact component={MonthlySummaryReportPage} />
              <AuthenticatedRoute path="/admin/transactions" exact component={AdminTransactionListPage} />
              <AuthenticatedRoute path="/admin/reports" exact component={AdminReportPage} />
              <AdminRoute path="/admin/system" exact component={AdminSettingPage} />

              <AdminRoute path="/admin/users" exact component={AdminUserListPage} />
              <AdminRoute path="/admin/items/:itemId" exact component={AdminEditItemPage} />
              <AuthenticatedRoute
                path="/client/:clientId/transaction/new"
                exact
                component={ClientCreateTransactionPage}
              />
              <Route path="/client/:clientId" exact component={ClientPage} />

              <OnlyAnonymousRoute path="/login" exact component={LoginPage} />
              <Route exact component={NotFoundPage} />
            </Switch>
          </Suspense>
        </TokenVerifier>
      </MainLayout>
      <Footer />
    </Router>
  );
};

export default App;
