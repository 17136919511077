import React, { Fragment, useRef } from 'react';
import DataTable from '../../components/DataTable';
import { Column } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avartar: {
    marginRight: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AdminBuildingFloorPage = () => {
  const classes = useStyles({});
  const tableRef = useRef<any>(React.createRef());

  const columns: Column<object>[] = [
    {
      title: 'Fullname',
      field: 'buildingFullname',
      filtering: false,
      sorting: false,
    },
    {
      title: 'ชื่อ',
      field: 'name',
    },
    {
      title: 'ภายใต้',
      field: 'parent.name',
    },
    {
      title: 'องค์กร',
      field: 'group.name',
    },
  ];

  return (
    <DataTable
      columns={columns}
      title="ตึก / ชั้น"
      resource="buildings"
      tableRef={tableRef}
      crudActions={['add', 'update', 'delete']}
      actions={
        [
          // {
          //   icon: 'morevert',
          //   tooltip: 'Action Menus',
          //   onClick: () => {}
          // }
        ]
      }
      components={
        {
          // Action: props => (
          // <ActionsMenu
          //   data={props.data}
          //   refresh={() => {
          //     tableRef.current.onQueryChange();
          //   }}
          // />
          // )
        }
      }
    />
  );
};

export default AdminBuildingFloorPage;
