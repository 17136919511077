/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

export const Dashboard = () => {
  return (
    <div style={{ height: '100vh' }}>
      <iframe
        width="100%"
        height="100%"
        src={
          process.env.REACT_APP_DATA_STUDIO ||
          'https://datastudio.google.com/embed/reporting/0b878f34-a71c-42fa-b544-d69b08486ac2/page/7VVRB'
        }
        style={{ border: 0 }}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};
