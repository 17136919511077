import { createMuiTheme } from '@material-ui/core/styles';
import { blue, pink } from '@material-ui/core/colors';

// A theme with custom primary and secondary color.
// It's optional.
export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Bai Jamjuree',
      'Kanit',
      'Prompt',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: {
      fontFamily: ['Kanit', 'Roboto', 'sans-serif'].join(','),
      fontSize: '5.675rem',
      fontWeight: 300,
      lineHeight: 1
    },
    h2: {
      fontFamily: ['Kanit', 'Roboto', 'sans-serif'].join(','),
      fontSize: '3.575rem',
      fontWeight: 300,
      lineHeight: 1
    },
    h3: {
      fontFamily: ['Kanit', 'Roboto', 'sans-serif'].join(','),
      fontSize: '2.175rem',
      fontWeight: 300,
      lineHeight: 1.25
    },
    h4: {
      fontFamily: ['Kanit', 'Roboto', 'sans-serif'].join(','),
      fontSize: '1.875rem',
      fontWeight: 400,
      lineHeight: 1.25
    },
    h5: {
      fontFamily: ['Kanit', 'Roboto', 'sans-serif'].join(','),
      fontSize: '1.35rem',
      fontWeight: 400,
      lineHeight: 1.25
    },
    h6: {
      fontFamily: ['Kanit', 'Roboto', 'sans-serif'].join(','),
      fontSize: '1.15rem',
      fontWeight: 400,
      lineHeight: 1.25
    },
    subtitle1: {
      fontFamily: ['"Sukhumvit Set"', 'Roboto', 'sans-serif'].join(','),
      fontWeight: 400
    },
    subtitle2: {
      fontFamily: ['"Sukhumvit Set"', 'Roboto', 'sans-serif'].join(','),
      fontWeight: 400
    },
    body1: {
      fontFamily: ['Bai Jamjuree', 'Sarabun', 'Roboto', 'sans-serif'].join(','),
      lineHeight: 1.69
    },
    body2: {
      fontFamily: ['Bai Jamjuree', 'Sarabun', 'Roboto', 'sans-serif'].join(',')
    }
  },
  palette: {
    primary: blue,
    secondary: pink
  },
  overrides: {
    MuiInputBase: {
      input: {
        height: '1.3875em'
      }
    },
    MuiExpansionPanel: {
      root: {
        transition: 'none'
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        transition: 'none'
      },
      content: {
        transition: 'none'
      }
    }
  }
});
