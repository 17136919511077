import * as bblob from 'base64-blob'
import { observable, computed, action } from "mobx";
import { createContext } from "react";

export class TransactionConfirmStore {
    // Form
    @observable signatureBase64: string;
    @observable remark: string = '';
    @observable isPaid: boolean = false;

    // Signature (react-sketch)
    value: any;
    blob: any;

    @computed
    get signatureAsBlob() {
        return bblob.base64ToBlob(this.signatureBase64)
    }

    @action
    async setSignatureBase64(base64: string) {
        this.signatureBase64 = base64;
        const blob = await bblob.base64ToBlob(this.signatureBase64);
        this.blob = blob;
    }

    @computed
    get isSign(): boolean {
        return !!this.signatureBase64;
    }

    @action
    resetState() {
        this.signatureBase64 = '';
        this.remark = '';
        this.isPaid = false;
    }
}

export const TransactionConfirmStoreContext = createContext(new TransactionConfirmStore())
