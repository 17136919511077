import React, { useEffect } from 'react';
import { useLocation, RouteProps, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../stores';
import NotFoundPage from '../pages/NotFoundPage';

/**
 * scroll to the top on every navigation
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
 */
export const ScrollRestoration = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export interface AuthenticatedRouteProps extends RouteProps {
  loginPath?: string;
}

export const AuthenticatedRoute = observer(({ children, loginPath = '/login', ...props }: AuthenticatedRouteProps) => {
  const { isAuth } = useAuth();
  const location = useLocation();

  if (!isAuth) {
    const blacklist = ['/', '/login'];
    const redirectTo = !blacklist.includes(location.pathname) ? location.pathname : '/';
    const loginPathWithRedirect = `${loginPath}?redirectTo=${redirectTo}`;
    return <Redirect to={loginPathWithRedirect} />;
  }

  return <Route {...props} />;
});

export const AdminRoute = observer((props: AuthenticatedRouteProps) => {
  const { isAuth, userType } = useAuth();

  if (isAuth && userType !== 'admin') {
    const { children, loginPath, ...rest } = props;
    return <Route {...rest} exact component={NotFoundPage} />;
  }

  return <AuthenticatedRoute {...props} />;
});

export const OnlyAnonymousRoute = observer(({ children, ...props }: AuthenticatedRouteProps) => {
  const { isAuth } = useAuth();

  if (isAuth) return <Redirect to="/" />;

  return <Route {...props} />;
});
