import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { action, observable, runInAction } from 'mobx';

import { client } from '../../api';
import { TransactionItemCreateDto } from '../../models';
import Loadable from '../Loadable';

export class TransactionItemStore {
  @observable public transactionId?: string;
  @observable public items = Loadable.create<any[]>([]);

  constructor() {
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  @action
  async fetch() {
    if (this.items.isLoading()) return;

    this.items.setLoading(true);
    const queryString = this.fetchQueryString();
    const { data: payload } = await client.get(`/transaction-items?${queryString}`);

    runInAction('fetchTransactionItemListSuccess', () => {
      this.items.set(payload.data);
    });
  }

  private fetchQueryString(): string {
    return RequestQueryBuilder.create({
      sort: [{ field: 'createdAt', order: 'ASC' }],
      filter: [{ field: 'transactionId', operator: '$eq', value: this.transactionId }]
    }).query();
  }

  @action
  async create(dto: Partial<TransactionItemCreateDto>) {
    this.items.setLoading(true);

    const { itemId, ...itemData } = dto;

    const { data } = await client.post<any>(`/transaction-items`, {
      ...itemData,
      item: {
        id: itemId
      },
      transaction: {
        id: this.transactionId
      }
    });

    runInAction('createTransactionItemSuccess', () => {
      const items = this.items.val()!.concat(data);
      this.items.set(items);
    });
  }

  @action
  async update(itemId: string, dto: Partial<TransactionItemCreateDto>) {
    this.items.setLoading(true);
    const { data } = await client.patch<any>(`/transaction-items/${itemId}`, {
      ...dto
    });

    runInAction('updateTransactionItemSuccess', () => {
      const items = this.items.val()!.map((item: any) => {
        if (item.id === itemId) {
          return data;
        }
        return item;
      });
      this.items.set(items);
    });
  }

  @action
  async delete(itemId: string) {
    this.items.setLoading(true);
    await client.delete(`/transaction-items/${itemId}`);

    runInAction('deleteTransactionItemSuccess', () => {
      const items = this.items.val()!.filter((item: any) => item.id !== itemId);
      this.items.set(items);
    });
  }
}
