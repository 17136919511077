import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useStores } from '../stores';
import { autorun } from 'mobx';

const Notifier = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { notiStore } = useStores();

  useEffect(() => {
    let displayed: any = [];

    const storeDisplayed = (id: number) => {
      displayed = [...displayed, id];
    };

    autorun(() => {
      const notifications = notiStore.notifications;
      notifications.forEach((notification: any) => {
        if (displayed.includes(notification.key)) return;
        enqueueSnackbar(notification.message, notification.options);
        storeDisplayed(notification.key);
        notiStore.removeSnackbar(notification.key);
      });
    });
  }, [enqueueSnackbar, notiStore]);

  return null;
};

export default observer(Notifier);
