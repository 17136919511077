import { action, observable, runInAction, computed } from 'mobx';
import moment from 'moment';

import Loadable from './Loadable';
import { client } from '../api';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

export class DailyPageStore {
  @observable date = new Date();

  /**
   * สรุปประจำวัน
   */
  @observable data = Loadable.create<any[]>([]);

  /**
   * งานคงค้าง
   */
  @observable overdueTasks = Loadable.create(null);

  /**
   * งานวันนี้
   */
  @observable dailyTask = Loadable.create(null);

  @action
  async fetchDateSummary() {
    if (this.data.isLoading()) return;
    this.data.setLoading(true);

    try {
      const date = this._moment.format('YYYY-MM-DD');
      const queryString = RequestQueryBuilder.create({
        filter: [{ field: 'date', operator: '$eq', value: date }]
      }).query();
      const { data: payload } = await client.get(`operation-tasks?${queryString}`);
      runInAction('Fetch Daily data successfully', () => {
        this.data.set(payload.data);
      });
    } catch (ex) {
      runInAction('Fetch Daily data failed', () => {
        console.error(ex);
      });
    }
  }

  @action
  async fetchOverdueTasks() {
    if (this.overdueTasks.isLoading()) return;
    this.overdueTasks.setLoading(true);

    try {
      const date = this._moment.format('YYYY-MM-DD');
      const queryString = RequestQueryBuilder.create({
        filter: [
          { field: 'date', operator: '$lt', value: date },
          { field: 'state', operator: '$eq', value: 'INCOMPLETE' }
        ],
        sort: [{ field: 'date', order: 'ASC' }]
      }).query();
      const { data: payload } = await client.get(`operation-tasks?${queryString}`);
      runInAction('Fetch Daily OverdueTasks successfully', () => {
        this.overdueTasks.set(payload.data);
      });
    } catch (ex) {
      runInAction('Fetch Daily OverdueTasks failed', () => {
        console.error(ex);
      });
    }
  }

  @action
  async fetchDailyTasks(state: string) {
    if (this.dailyTask.isLoading()) return;
    this.dailyTask.setLoading(true);

    try {
      const date = this._moment.format('YYYY-MM-DD');
      const queryString = RequestQueryBuilder.create({
        filter: [
          { field: 'date', operator: '$eq', value: date },
          { field: 'state', operator: '$eq', value: state }
        ],
        sort: [{ field: 'date', order: 'ASC' }]
      }).query();
      const { data: payload } = await client.get(`operation-tasks?${queryString}`);
      runInAction('Fetch Daily dailyTask successfully', () => {
        this.dailyTask.set(payload.data);
      });
    } catch (ex) {
      runInAction('Fetch Daily dailyTask failed', () => {
        console.error(ex);
      });
    }
  }

  @computed
  get _moment(): moment.Moment {
    return moment(this.date);
  }

  @computed
  get allTasks(): number {
    const tasks = this.data.val();
    if (!tasks) return 0;
    return tasks.length;
  }

  @computed
  get incompleteTasks(): number {
    const tasks = this.data.val();
    if (!tasks) return 0;
    return tasks.filter(task => task.state === 'INCOMPLETE').length;
  }
}
