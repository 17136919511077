import { firebase } from './index';
import { CurrentAuthContext } from '../contexts/CurrentAuth';
import React, { FC, useEffect } from 'react';

const FirebaseAuthWrapper: FC<{}> = ({ children }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const currentAuth = React.useContext(CurrentAuthContext);

  useEffect(() => {
    setLoading(true);
    firebase.auth().onAuthStateChanged(
      async user => {
        if (user) {
          const token = await user.getIdToken(true);
          currentAuth.setJwtToken(token);
        }
        setLoading(false);
      },
      error => {
        currentAuth.setJwtToken(null);
        setLoading(false);
      }
    );
  }, [currentAuth]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default FirebaseAuthWrapper;
