import './index.css';
import './firebase';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import ReactDOM from 'react-dom';

import ApolloWrapper from './ApolloWrapper';
import App from './App';
import AuthWrapper from './AuthWrapper';
import * as serviceWorker from './serviceWorker';
import { theme } from './utils/theme';
import { CssBaseline } from '@material-ui/core';
import FirebaseAuthWrapper from './firebase/FirebaseAuthWrapper';
import { UniqueIdFactoryContext, UniqueIdFactory, globalIdGeneratorFactory } from '@mystiny/ui';

const uniqueIdFactory = new UniqueIdFactory(globalIdGeneratorFactory);

ReactDOM.render(
  <AuthWrapper>
    <FirebaseAuthWrapper>
      <ApolloWrapper>
        <UniqueIdFactoryContext.Provider value={uniqueIdFactory}>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <SnackbarProvider maxSnack={3}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </SnackbarProvider>
            </CssBaseline>
          </ThemeProvider>
        </UniqueIdFactoryContext.Provider>
      </ApolloWrapper>
    </FirebaseAuthWrapper>
  </AuthWrapper>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
