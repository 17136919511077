import { observable, computed, action, runInAction } from 'mobx';
import { client } from '../../api';
import { AdminStores } from '.';
import { RootStore } from '../RootStore';
import { normalize } from '../../utils/data';
const SUBMIT_SUCCESSFULLY_TEXT = 'บันทึกสำเร็จ';
const FAILURE_TEXT = 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง';

export type NotificationText = {
  SUBMIT_SUCCESSFULLY_TEXT?: string;
  FAILURE_TEXT?: string;
};

export abstract class AdminCrudStore<T = any> {
  /**
   * Resource URI สำหรับต่อกับ Backend
   * เอามาจาก API endpoint
   */
  abstract resource: string;
  @observable data: T & { id: string };
  @observable state: 'SUCCESS' | 'FAIL' | 'FETCHING' | 'SUBMITTING' | 'CREATING' | 'UPLOADIMAGE';
  notificationText: NotificationText = {
    SUBMIT_SUCCESSFULLY_TEXT,
    FAILURE_TEXT
  };

  constructor(public readonly stores: AdminStores) {}

  @action
  async fetch(dataId: string) {
    if (this.fetching) return;
    this.state = 'FETCHING';
    try {
      const { data } = await client.get(`${this.resource}/${dataId}`);
      runInAction(() => {
        this.data = data;
        this.state = 'SUCCESS';
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        this.state = 'FAIL';
        this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT, {
          variant: 'error'
        });
      });
    }
  }

  /**
   * สำหรับใช้ตอน Submit form
   * @param data <T>
   */
  @action
  async submit(data: T & { id: string }) {
    if (this.submitting) return;
    this.state = 'SUBMITTING';
    try {
      // ใช้ PATCH เพราะ react-hook-form จัดการ State เฉพาะ field ที่ register เท่านั้น
      // ไม่ได้จัดการทั้ง Object ที่ Pass ใส่ใน DefaultValues เลยจะมีค่าเฉพาะที่จัดการในฟอร์ม
      await client.patch(`${this.resource}/${this.data.id}`, data);

      runInAction(() => {
        this.state = 'SUCCESS';
        this.stores.notiStore.enqueueSnackbar(this.notificationText.SUBMIT_SUCCESSFULLY_TEXT, {
          variant: 'success'
        });
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        this.state = 'FAIL';
        this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT, {
          variant: 'error'
        });
      });
    }
  }

  @action
  async create(data: T) {
    if (this.creating) return;
    this.state = 'CREATING';
    try {
      await client.post(this.resource, normalize(data));

      console.log('helloworld');

      runInAction(() => {
        this.state = 'SUCCESS';
        this.stores.routingStore.push(`/admin${this.resource}`, false);
        this.stores.notiStore.enqueueSnackbar(this.notificationText.SUBMIT_SUCCESSFULLY_TEXT, {
          variant: 'success'
        });
      });
    } catch (ex) {
      runInAction(() => {
        console.error(ex);
        this.state = 'FAIL';
        this.stores.notiStore.enqueueSnackbar(this.notificationText.FAILURE_TEXT, {
          variant: 'error'
        });
      });
    }
  }

  @computed
  get fetching(): boolean {
    return this.state === 'FETCHING';
  }

  @computed
  get submitting(): boolean {
    return this.state === 'SUBMITTING';
  }
  @computed
  get creating(): boolean {
    return this.state === 'CREATING';
  }
}
