import { observable, action } from 'mobx';

export class AppStore {
  @observable sidebar: boolean = false;

  @action
  toggleSidebar() {
    this.sidebar = !this.sidebar;
  }
}
