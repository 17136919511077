import { action, observable, runInAction } from 'mobx';

import { client } from '../api';
import Loadable from './Loadable';

export class BuildingStore {
  @observable public building = Loadable.create<any[]>();
  @observable search: string | undefined;
  @action
  async fetch() {
    if (this.building.val()) return;
    try {
      this.building.setLoading(true);
      const { data: payload } = await client.get(`/buildings`);

      runInAction('fetchbuilding', () => {
        this.building.set(payload.data);
      });
    } catch (error) {
      runInAction('fetchbuildingFailed', () => {
        console.error(error);
        this.building.setLoading(false);
      });
    }
  }
  async setSearch(search: string | undefined) {
    if (this.building.isLoading()) return;
    try {
      runInAction('setSearch', () => {
        this.search = search;
      });
    } catch (error) {
      runInAction('fetchbuildingFailed', () => {
        console.error(error);
        this.building.setLoading(false);
      });
    }
  }
}
