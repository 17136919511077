import { createContext, useContext } from 'react';
import { decode } from 'jsonwebtoken';

export enum AuthRole {
  Operation = 'operation',
  Client = 'client',
  Admin = 'admin'
}

export const AuthStorageKey = 'mntg.jwtToken';

export type CurrentAuthContextType = {
  jwtToken: string | null;
  setJwtToken: (jwtToken: string | null) => void;
};

export const CurrentAuthContext = createContext<CurrentAuthContextType>({
  jwtToken: null,
  setJwtToken: () => {}
});

export const useCurrentAuth = () => {
  const context = useContext(CurrentAuthContext);

  if (!context) {
    throw new Error('');
  }

  if (!context.jwtToken) {
    return null;
  }

  return decode(context.jwtToken);
};

export type RoleType = {
  'x-hasura-allowed-roles': string[];
  'x-hasura-default-role': 'admin' | 'operation' | 'client';
  'x-hasura-firebase-id': string;
  'x-hasura-role': string;
  'x-hasura-user-id': string;
};

export const useCurrentRole = (): RoleType | null => {
  const user = useCurrentAuth();
  return user ? user['https://hasura.io/jwt/claims'] : null;
};
